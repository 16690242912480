.User-Wrapper {
  padding: 1rem 3rem;
  max-width: 1260px;
  margin: auto;
  &-ButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  h1 {
    text-align: center;
  }

  .Password {
    margin-top: 1.5rem;
    &-Hint {
      font-size: 0.75rem;
    }

    &-Error {
      height: 1rem;
      font-size: 0.75rem;
      color: #111;
    }
  }

  .Save-Confirm {
    margin-top: 1rem;
    text-align: center;
  }
}

.Device {
  &-SubCategory-Header {
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }
  &-Wrapper {
    padding: 1rem;

    @media (min-width: 912px) {
      padding: 1rem 2rem;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-ButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  &-ControlsWrapper {
    flex-grow: 1;
    width: 100%;
    max-width: 1260px;

    .Variant-Link {
      text-decoration: none;
      li {
        color: #fff;

        &:hover {
          color: #000;
        }
      }
    }
  }

  &-SubCategory {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-Image {
    max-width: 150px;
  }

  &-Checkboxes-Wrapper {
    display: flex;
    flex-wrap: wrap;

    &-Box {
      margin-right: 2rem;
    }
  }

  &-Languages {
    display: flex;
    flex-direction: column;

    &-Language {
      font-weight: bold;
    }
  }

  &-Equipment-Combinations {
    display: flex;
    flex-direction: column;

    &-Form {
      text-align: center;
    }

    &-Active {
      display: flex;
      flex-wrap: wrap;
      margin-top: 1.25rem;
      &-Item {
        display: flex;
        justify-content: space-between;
        width: 30%;
        min-width: 18rem;
        margin: 0.25rem 1rem 0 0;
      }
    }
  }
  &-New-Equipment {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    &-AddBtn {
      margin-left: 1rem;
    }
  }
}

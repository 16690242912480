.Device-SubNav {
  &-Item {
    background: transparent;
    color: white;
    border: none;
    border-bottom: solid 1px white;
    text-transform: uppercase;

    &:focus {
      outline: none;
    }

    &:hover {
      color: #111;
    }

    &-active {
      background: transparent;
      border: none;
      text-transform: uppercase;
      color: #111;
      border-bottom: solid 1px white;
      &:focus {
        outline: none;
      }
    }
  }
}

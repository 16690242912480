.Organization-Wrapper {
  padding: 1rem 3rem;
  max-width: 1260px;
  margin: auto;
  &-ButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  h1 {
    text-align: center;
  }

  .Password {
    margin-top: 1.5rem;
    &-Hint {
      font-size: 0.75rem;
    }

    &-Error {
      height: 1rem;
      font-size: 0.75rem;
      color: #111;
    }
  }

  .Save-Confirm {
    margin-top: 1rem;
    text-align: center;
  }

  .discount-label {
    font-weight: bold;
    font-size: 0.75rem;
    width: 100%;
    white-space: nowrap;
  }
  
  .discount-input {
    color: white;
    border: none;
    border-radius: 0;
    background: transparent;
    border-bottom: 1px solid #000;
    font-size: 1rem;
    padding: 0.25rem 0rem 0.25rem 0.5rem;
    width: 23%;
    text-align: center;
    margin-right: 0.25rem;
  
    &:focus {
      outline: none;
      border-bottom: 1px solid #fff;
    }
  }
}


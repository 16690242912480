.ButtonHollow {
  box-sizing: border-box;
  position: relative;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  letter-spacing: 0.5pt;
  padding: 0.5rem 2rem;
  text-transform: uppercase;
  overflow: hidden;
  width: 100%;
  max-width: 250px;
  font-weight: bold;
}

.ButtonHollow:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform: translateX(-102%);
  transition: transform 0.15s;
}

.ButtonHollow:hover {
  background-color: transparent !important;
  color: #2e559b;
}

.ButtonHollow:hover:before {
  transform: translateX(0);
  transition: transform 0.25s;
}

.ButtonHollow.disabled:before {
  background-color: transparent !important;
}

.LoginPage {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;

  &-Button {
    background-color: #26afff;
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
    padding: 0.65rem;
    outline: none;
    text-transform: uppercase;
    width: 100%;
  }

  &-ErrorMessage {
    font-size: 0.8rem;
    color: red;
  }

  &-Form {
    margin-top: 3rem;
    height: 100%;
    width: 100%;
  }

  &-InputArea {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 24rem;
    padding: 2rem;
    text-align: center;
    width: 24rem;
  }

  &-InputField {
    border: none;
    border-bottom: solid 1px lightgrey !important;
    font-size: 0.75rem;
    margin-bottom: 2rem;
    outline: none;
    width: 100%;
    padding: 0.4rem 0;

    &::placeholder {
      color: #aaa;
    }

    &:focus {
      border-bottom: solid 1px black !important;
    }
  }
}

.Category {
  &-Results-Wrapper {
    margin-top: 2rem;
    justify-content: center;
  }

  &-Result {
    height: 2rem;
    display: flex;
    margin-bottom: 0.5rem;
    justify-content: space-between;
    width: 500px;
    &-Button {
      width: auto;
      padding: 0.25rem 1rem;
    }
  }
  &-Wrapper {
    padding: 1rem;
    max-width: 1260px;
    margin: auto;

    @media (min-width: 912px) {
      padding: 1rem 2rem;
    }

    h1 {
      text-align: center;
      margin-bottom: 3rem;
    }

    h3 {
      text-align: center;
      margin: 2.5rem 0;
    }
  }

  &-ButtonContainer {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  &-ControlsWrapper {
    max-width: 1260px;
  }

  &-SubCategory {
    margin-bottom: 3rem;
    display: flex;
    flex-wrap: wrap;
  }

  &-Image {
    max-width: 150px;
  }

  &-Checkboxes-Wrapper {
    display: flex;
    flex-wrap: wrap;

    &-Box {
      margin-right: 2rem;
    }
  }

  &-Languages {
    display: flex;
    flex-direction: column;
  }

  &-Equipment-Combinations {
    display: flex;
    flex-wrap: wrap;

    &-Item {
      display: flex;
      justify-content: space-between;
      width: 45%;
      margin-right: 1rem;
    }
  }
  &-New-Equipment {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    &-AddBtn {
      margin-left: 1rem;
    }
  }
}

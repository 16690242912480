.toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  animation: slideIn 0.5s ease-in-out;

  @keyframes slideIn {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  &-success {
    background-color: #28a745;
  }

  &-error {
    background-color: #dc3545;
  }

  &-warning {
    background-color: #ffc107;
  }

  &-info {
    background-color: #17a2b8;
  }

  .toast-close {
    appearance: none;
    color: white;
    background: transparent;
    border: none;
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
  }

  .toast-message {
    margin-right: 20px;
  }

  .toast-icon {
    margin-right: 10px;
  }

  .toast-title {
    font-weight: bold;
  }

  .toast-description {
    font-size: 14px;
  }

  .toast-actions {
    margin-top: 10px;
  }

  .toast-action {
    margin-right: 10px;
  }

  .toast-action:last-child {
    margin-right: 0;
  }

  .toast-action button {
    background-color: #fff;
    color: #333;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .toast-action button:hover {
    background-color: #f1f1f1;
  }

  .toast-action button:active {
    background-color: #e9e9e9;
  }

  .toast-action button:focus {
    outline: none;
  }

  .toast-action button:disabled {
    background-color: #f1f1f1;
    color: #ccc;
    cursor: not-allowed;
  }

  .toast-action button:disabled:hover {
    background-color: #f1f1f1;
  }

  .toast-action button:disabled:active {
    background-color: #f1f1f1;
  }

  .toast-action button:disabled:focus {
    outline: none;
  }

  .toast-action button:not(:disabled):hover {
    background-color: #e9e9e9;
  }

  .toast-action button:not(:disabled):active {
    background-color: #e1e1e1;
  }

  .toast-action button:not(:disabled):focus {
    outline: none;
  }
}

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.LoadingSpinner {
  content: "";
  height: 32px;
  width: 32px;
  border: 3px solid #fff;
  border-radius: 50%;
  border-top-color: transparent;
  border-right-color: transparent;
  margin: 2rem auto;

  animation: spinAround 0.5s infinite linear;
}

.Loadingspinner.inverted {
  border: 3px solid #fff;
}
